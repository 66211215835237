import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CountryCode, isValidNumber } from 'libphonenumber-js';


/**
 * Validador que comprueba si un número de teléfono es válido para un código de país.
 * Recibe el codigo ISO del pais y mediante el método isValidNumber de la librería libphonenumber-js
 * comprueba si el número de teléfono es válido para el país. Si lo es devuelve null, si no, devuelve un objeto con el error correspondiente.
 * 
 * LIBPHONENUMBER-JS
 * https://gitlab.com/catamphetamine/libphonenumber-js
 * @param code 
 * @returns 
 */
export function phoneValidator( code: CountryCode ): ValidatorFn {
  return (control: AbstractControl<number>): { [key: string]: any } | null => {

    const valid: boolean = control.value ? 
      isValidNumber(control.value.toString(), code) : 
      false;
    
    return valid ? null : { invalidPhoneNumber: { value: control.value } };

  };
}