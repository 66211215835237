import { AbstractControl, ValidatorFn } from '@angular/forms';

// DICCIONARIOS
const zipCodePatterns: { [key: string]: RegExp } = {
  'ESP': /^[0-9]{5}$/,
  'FRA': /^[0-9]{5}$/,
  'ITA': /^[0-9]{5}$/,
  'AUS': /^[0-9]{4}$/,
  'DEU': /^[0-9]{5}$/,
  'BEL': /^[0-9]{4}$/,
  'DNK': /^[0-9]{4}$/,
  'USA': /^(\d{5})(?:-?(\d{4}))?$/,
  'CAN': /^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$/,
  'GBR': /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i,
    // ...
};

// VALIDATOR
/**
 * Función que devuelve un validador para un formulario reactivo. El validador recibe un codigo ISO
 * Comprueba si para el codigo facilitado existe una regex en el diccionario correspondiente
 * y si es así, comprueba si el valor del control cumple con la regex. Si no, se devuelve un objeto con el error correspondiente.
 * 
 * Si no hay regex para el país, no se valida.
 * @param country 
 * @returns 
 */
export function zipCodeValidator( country: string ): ValidatorFn {
    
  return (control: AbstractControl): { [key: string]: any } | null => {

    const pattern: RegExp = zipCodePatterns[country];
    
    if (!pattern) return null; 

    const valid:   boolean = pattern.test(control.value);
    
    return valid ? null : { invalidZipCode : { value: control.value } };
    
  };
}
