import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TdcInfo } from 'src/app/shared/models/user.model';
import countriesData from 'src/assets/data/countries-code.json';
import subCountriesData from 'src/assets/data/sub-country-dict.json';
import { CountryData } from 'src/app/shared/models/countries.model';
import { skip, Subscription } from 'rxjs';
import { PersonalDataModel } from './Personal-data.model';
import { zipCodeValidator } from 'src/app/utils/validators/zipcode-validator.helpers';
import { phoneValidator } from 'src/app/utils/validators/phone-validator.helper';
import { CountryCode } from 'libphonenumber-js';


@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrl: './personal-data-form.component.css'
})
export class PersonalDataFormComponent implements OnInit, OnDestroy {
  
  // INPUTS / OUTPUTS
  @Input()
  public userData!:   TdcInfo | undefined;
  
  @Output()
  public updateData:  EventEmitter<PersonalDataModel['values']> = new EventEmitter<PersonalDataModel['values']>();

  // COUNTRIES DATA
  public countryData: CountryData = {
    countries:              countriesData,
    subcountries:           subCountriesData,
    availableSubcountries:  [],
    defaultCountry:         'United Kingdom',
    hasSubcountries:        false
  }
  
  // FORM VARS
  protected personalDataForm: FormGroup<PersonalDataModel['form']> = new FormGroup<PersonalDataModel['form']>({
      
    firstName:    new FormControl<string>('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    lastName:     new FormControl<string>('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    gender:       new FormControl<string>('', [Validators.required]),
    birthDate:    new FormControl<Date>(new Date(), [Validators.required, Validators.max(parseInt(new Date().toDateString()))]),
    phoneNumber:  new FormControl<string>('', [Validators.required]),
    address:      new FormControl<string>('', [Validators.required]),
    address2:     new FormControl<string>(''),
    zipCode:      new FormControl<string>('', [Validators.required]),
    country:      new FormControl<string>('', [Validators.required]),
    state:        new FormControl<string>('', [Validators.required]),
    city:         new FormControl<string>('', [Validators.required]),

  })

  public get form():  PersonalDataModel['form'] {
    return this.personalDataForm.controls;
  }

  public errors:      PersonalDataModel['errors'] = {
    max:                'Invalid date entered',
    maxlength:          'Field must be at most 50 characters long.',
    minlength:          'Field must be at least 3 characters long.',
    required:           'This field is required',
    invalidZipCode:     'Invalid zip code',
    invalidPhoneNumber: 'Invalid phone number',
  };

  // TITLE & SUBTITLE
  public title:       string = 'Personal Data';
  public subtitle:    string = 'Please ensure your address details (including state & postal code) are correct before making your purchase.';

  // SUBSCRIPTIONS
 private readonly   subscriptions: Subscription[]    = [];

  // LIFECYCLE HOOKS
  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy(): void {
    this.destroyComponent();
  }

  // PUBLIC METHODS
  /**
   * Método que se encarga de cerrar el modo de edición y resetear los campos del formulario a los valores originales
   * dependiendo si el usuario existe o es un formulario de registro.
   */
  public dismissChanges(): void {
    this.userData ? this.setUserData(this.userData) : this.personalDataForm.reset();
  }

  /**
   * Método público que inicia el proceso de envío de los datos del formulario.
   * Si el formulario no ha sido modificado, no se envía nada.
   * Cierra el modo de edición, obtiene los datos del formulario y llama al método privado _submitForm.
   */
  public submitForm(): void {
    
    if(!this.personalDataForm.pristine) {
    
      const value: PersonalDataModel['values'] = this.personalDataForm.getRawValue() as PersonalDataModel['values'];
      
      this.updateData.emit(value);
    }

    return;
   
  }

  // PRIVATE METHODS
  /**
   * Método que setea los datos del usuario en el formulario.
   * Este método se llama en el método initComponent si el usuario existe o si se resetea el formulario mediante 
   * el método dismissChanges.
   * @param userData 
   */
  private setUserData(userData: TdcInfo): void {
  
    // Personal Data
    this.personalDataForm.controls.firstName.setValue(userData.first_name);
    this.personalDataForm.controls.lastName.setValue(userData.last_name);
    this.personalDataForm.controls.gender.setValue(userData.gender);
    this.personalDataForm.controls.birthDate.setValue(userData.birthday);
    this.personalDataForm.controls.phoneNumber.setValue(userData.phone.number);

    // Address Data
    this.personalDataForm.controls.address.setValue(userData.address.address1);
    this.personalDataForm.controls.address2.setValue(userData.address.address2 ?? '');
    this.personalDataForm.controls.zipCode.setValue(userData.address.postal_code);
    this.personalDataForm.controls.city.setValue(userData.address.city);
    this.personalDataForm.controls.country.setValue(userData.address.address_country_code);    

  }

  /**
   * Método que se encarga de setear los subcountries en el formulario.
   * Si no existe el country code en el objeto de subcountries, se desactiva el campo de subcountry y se resetea el valor.
   * Si existe, se setean los subcountries disponibles y se activa el campo de subcountry.
   * @param subCountryCode 
   */
  private setSubcountry(subCountryCode?: string): void {
    
    const subCountryKeys: string[]    = Object.keys(this.countryData.subcountries),
          countryCode:    string|null = this.personalDataForm.controls.country.value;
    
    if(!countryCode || !subCountryKeys.includes(countryCode)){ 
      this.countryData.hasSubcountries = false;
      this.personalDataForm.controls.state.setValue('');
      return;
    }

    this.countryData.availableSubcountries = this.countryData.subcountries[countryCode];
    this.countryData.hasSubcountries       = true;
    
    const subCountrySelected: string = subCountryCode ? 
      this.countryData.availableSubcountries.filter(subCountry => subCountry.sub_country_code === subCountryCode)[0].sub_country_code : '';

    this.personalDataForm.controls.state.setValue(subCountrySelected);

  }

  /**
   * Inicializa el componente. 
   * Si existe input de userData, se setean los datos del usuario en el formulario y se desactiva el modo de edición.
   * Si no, se da por hecho que es un formulario de registro y el modo de edición está activado.
   * 
   * Además, se guarda en el array de Subscriptions el observable que se encarga de setear los subcountries. Si existe userData, 
   * skipea la primera llamada y se espera a que el country sea seteado por setUserData para llamar al método setSubcountry. Si no existe
   * userData, se llama directamente al método setSubcountry. 
   */
  private initComponent(): void {
       
    this.subscriptions.push(
      this.personalDataForm.controls.country.valueChanges.pipe(skip(1)).subscribe(()=>{
        this.setSubcountry(this.userData?.address.address_sub_country_code ?? '');
        this.updateValidators();
      })
    )

    this.userData ? this.setUserData(this.userData) : null;

  }

  /**
   * Método que se encarga de actualizar los validadores de los campos zipCode y phoneNumber dependiendo del país seleccionado.
   */
  private updateValidators(): void {
    
    // Obtenemos el ICAO Code y el ISO Code del país seleccionado más recientemente
    const country: string = this.personalDataForm.controls.country.value!;
    const isoCode: string = this.countryData.countries.find(c => c.icaoCode === country)?.code!;

    // Seteamos los validadores de los campos zipCode y phoneNumber dependiendo del país seleccionado
    this.personalDataForm.controls.zipCode.setValidators([zipCodeValidator(country)]);
    this.personalDataForm.controls.phoneNumber.setValidators([phoneValidator(isoCode as CountryCode)]);

    // Actualizamos los validadores de los campos zipCode y phoneNumber
    this.personalDataForm.controls.zipCode.updateValueAndValidity();
    this.personalDataForm.controls.phoneNumber.updateValueAndValidity();

  }

  /**
   * Método que se encarga de destruir el componente.
   * Se desuscribe de todos los observables guardados en el array de Subscriptions.
   */
  private destroyComponent(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
